/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import { AxiosInstance } from 'axios';
import { ProgramItem, ProgramItemSignalR, ProgramItemDetailSignalR } from '@/entities/Program/ProgramItem';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

const ProgramSignalRModelRequestIds = {
  BulkDelete: 1,
  BulkEditIconsAndImages: 2,
  SingleCreate: 3,
  SingleEdit: 4,
  SingleDelete: 5,
  BulkAssignTracks: 6,
  BulkLinkInformation: 7,
  BulkEditTags: 8,
  BulkEditDateTime: 9,
  SingleDetailEdit: 10,
  VideoUpload: 11,
  VideoDelete: 12,
  ExportPrograms:13,
  ImportPrograms: 14,
  UpdatePrograms: 15,
};
export class ProgramSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for deleting seleted Program Items in a batch
  get DeleteSelectedProgramSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.BulkDelete);
  }
  // Get SignalRModelRequest for updating Icons and featured images of Program Items
  get EditSelectedProgramIconsAndImagesSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.BulkEditIconsAndImages);
  }
  // Get SignalRModelRequest for adding a single program item
  get SingleCreateProgramItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single program item
  get SingleUpdateProgramItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single program item
  get SingleDeleteProgramItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.SingleDelete);
  }

  get BulkAssignTracksSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.BulkAssignTracks);
  }
  get BulkLinkInformationSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.BulkLinkInformation);
  }
  get BulkEditTagsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.BulkEditTags);
  }
  get BulkEditDateTimeSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.BulkEditDateTime);
  }
  // Get SignalRModelRequest for Updating a single program item
  get SingleDetailEditProgramItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.SingleDetailEdit);
  }
  // Get SignalRModelRequest for Updating a single program item
  get VodVideoUploadProgramItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.VideoUpload);
  }
  // Get SignalRModelRequest for Deleting a single Video
  get SingleVodVideoDeleteSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.VideoDelete);
  }
  // Get SignalRModelRequest for export programs
  get ExportProgramsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSignalRModelRequestIds.ExportPrograms);
  }

  readonly programBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly programPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPIProgramURL = Vue.prototype.$SignalRBaseURL + 'api/SignalRProgram/PushProgramSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.BulkDelete, ModuleEnum.Program, SubModuleEnum.Delete, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteProgramItems', 'Notifications.DeletedProgramItems', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingProgramDelets'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.BulkEditIconsAndImages, ModuleEnum.Program, SubModuleEnum.EditIconsAndImages,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateProgramItemsImage', 'Notifications.UpdatedProgramItemsImage', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingProgramImages'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.SingleCreate, ModuleEnum.Program, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingProgramItem', 'Notifications.SavedProgramItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleProgram'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.SingleEdit, ModuleEnum.Program, SubModuleEnum.SingleEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingProgramItem', 'Notifications.UpdatedProgramItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleProgram'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.SingleDelete, ModuleEnum.Program, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleProgramItem', 'Notifications.DeletedSingleProgramItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleProgram'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.BulkAssignTracks, ModuleEnum.Program, SubModuleEnum.AssignToTrack,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateProgramItemsTracks', 'Notifications.UpdatedProgramItemsTracks', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingTracks'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.BulkLinkInformation, ModuleEnum.Program, SubModuleEnum.LinkInformation,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateProgramItemsLinkedInformation', 'Notifications.UpdatedProgramItemsLinkedInformation', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingLinkedInformation'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.BulkEditTags, ModuleEnum.Program, SubModuleEnum.EditTags,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateProgramItemsTags', 'Notifications.UpdatedProgramItemsTags', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingTags'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.BulkEditDateTime, ModuleEnum.Program, SubModuleEnum.EditDateAndTime,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateProgramItemsDateTime', 'Notifications.UpdatedProgramItemsDateTime', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingDateTime'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.SingleDetailEdit, ModuleEnum.Program, SubModuleEnum.SingleDetailEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingProgramItem', 'Notifications.UpdatedProgramItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleProgram'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.VideoUpload, ModuleEnum.Program, SubModuleEnum.MuxVodUpdate, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingProgramItem', 'Notifications.UpdatedProgramItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionOnVideoOnDemandOperation'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.VideoDelete, ModuleEnum.Program, SubModuleEnum.MuxVodDelete, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeletedSingleVideo', 'Notifications.DeletedSingleVideo', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionOnVideoOnDemandOperation'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.ExportPrograms, ModuleEnum.Program, SubModuleEnum.ExportPrograms, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.ExportPrograms', '', 'Notifications.ExportPrograms', 'Notifications.ExportPrograms', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', ''));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.ImportPrograms, ModuleEnum.Program, SubModuleEnum.ImportPrograms, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.ImportPrograms', '', 'Notifications.ImportingPrograms', 'Notifications.ImportedPrograms', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionProgramImport'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSignalRModelRequestIds.UpdatePrograms, ModuleEnum.Program, SubModuleEnum.UpdatePrograms, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdatePrograms', '', 'Notifications.UpdatingPrograms', 'Notifications.UpdatedPrograms', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionProgramImport'));
  }
  // Perform Program Batch Operations
  public PerformProgramBatchOperation(signalRModelRequest: SignalRModelRequest) {
    // disable program items
    if (signalRModelRequest.SelectedIds !== null && signalRModelRequest.SelectedIds !== undefined && signalRModelRequest.SelectedIds.length > 0) {
      this.DisableProgramItems(signalRModelRequest);
    }
    this.SendSignalRModelRequestToHub(signalRModelRequest, this.programBatchOperationURL);

  }

  // Perform Program Single Operations
  public PerformProgramSingleOperation(signalRModelRequest: SignalRModelRequest, isProgramDetails?: boolean) {
    // disable program items
    if (signalRModelRequest.SelectedIds.length > 0) {
      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && isProgramDetails === false) {
        this.DisableProgramItems(signalRModelRequest);
      }
    }

    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.programPostToClientsSignalRURL);

    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.programBatchOperationURL);

  }
  // Method that fires when Program SignalR response model is received from Hub
  public ProgramSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    if (signalRModelRequest.SubModule !== SubModuleEnum.MuxVodUpdate) {
      this.SignalRModelResponseReceived(signalRModelRequest);
    }
    // enable program items
    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete && signalRModelRequest.SubModule !== SubModuleEnum.ImportPrograms && signalRModelRequest.SubModule !== SubModuleEnum.UpdatePrograms ) {
      if (this.IsLoggedInUserIsMe(signalRModelRequest)) {
        this.EnableProgramItems(signalRModelRequest);
      }
    }
  }

  // Disable Program Items and add to Notification Popup that an operation has started

  public DisableProgramItemsAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.SingleDetailEdit) {
      this.DisableProgramItems(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }

  // Get Program Item to send to SignalR as JSON data
  public GetProgramItemToSendToSignalR(programItem: ProgramItem): string {
    const item: ProgramItemSignalR = ProgramItemSignalR.GetProgramSignalRItem(programItem);
    return JSON.stringify(item);
  }
  // Get Program Detail Item to send to SignalR as JSON data
  public GetProgramDetailItemToSendToSignalR(programDetailItem: any): string {
    const item: ProgramItemDetailSignalR = ProgramItemDetailSignalR.GetProgramDetailSignalRItem(programDetailItem);
    return JSON.stringify(item);
  }

  // Update programItem in array based on Index for data received from Signal R
  public FillProgramObjectFromSignalRResponse(
    programItem: ProgramItemSignalR,
    objIndex: number,
    ProgramList: ProgramItem[],
  ): ProgramItem {
    const pItem = this.GetProgramItem(ProgramList[objIndex], programItem);
    return pItem;
  }

  // Create new Program Item based on Response received from SignalR
  public FillProgramObjectFromSignalRResponseNewItem(
    programItem: ProgramItemSignalR,
  ): ProgramItem {
    const pItem = this.GetProgramItem(ProgramItem.createEmpty(), programItem);
    return pItem;
  }

  public GetSelectedProgramIdsToSendInJson(selectedItems: ProgramItem[]): number[] {
    const selectedProgramIds: number[] = [];
    selectedItems.forEach((element) => {
      selectedProgramIds.push(element.Id);
    });
    return selectedProgramIds;
  }

  // Disable Selected Program Items based on program Ids
  private DisableProgramItems(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.disableProgramItems, signalRModelRequest.SelectedIds);
  }

  // Enable Selected Program Items based on program Ids
  private EnableProgramItems(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.enableProgramItems, signalRModelRequest.SelectedIds);
  }

  // Get Program Item from ProgramSignalR Item
  private GetProgramItem(pItem: ProgramItem, programItem: ProgramItemSignalR): ProgramItem {
    pItem = ProgramItem.GetProgramItemFromProgramItemSignalR(pItem, programItem);
    return pItem;
  }
}
