



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import EmitEnum from '@/enums/EmitEnums';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';
import ModuleEnum from '../../enums/ModuleEnum';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import SubModuleEnum from '../../enums/SubModuleEnum';
@Component
export default class NotificationPopup extends Vue {
  @Prop() private showNotificationPopup?: boolean;
  private localNotifications?: SignalRModelRequest[] = [];
  private loadingImage: string = require('@/assets/Images/loading-dots.gif');
  private showNotificationLoading = false;
  private isMobileView = false;
  private notificationSending: number = NotificationStatusEnum.Sending;
  private notificationReceived: number = NotificationStatusEnum.Received;
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  // Mounted Event of component
  private mounted() {
    this.CallrootLeveleEmits();
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  private mouseOver() {
    this.$emit('mouse-over-notification', true);
  }
  private mouseLeave() {
    this.$emit('mouse-over-notification', false);
  }
  private GetPopupCSSClass(): string {
    let className = '';
    if (this.isMobileView) {
      if (this.showNotificationLoading) {
        className = 'notifications-with-loading-mobile';
      } else {
        className = 'notifications-without-loading-mobile';
      }
    } else {
      if (this.showNotificationLoading) {
        className = 'notifications-with-loading';
      } else {
        className = 'notifications-without-loading';
      }
    }
    return className;
  }
  // Call root level emits
  private CallrootLeveleEmits() {
    this.$root.$on(
      EmitEnum.addNewNotification,
      (notification: SignalRModelRequest) => {
        this.AddUpdateNotificationStatus(notification);
      },
    );

    this.$root.$on('show-notification-loading', (show: boolean) => {
      this.showNotificationLoading = show;
    });
    this.$root.$on('reload-all-notifications', () => {
      this.RefreshNotificationrelativeTime();
    });

    this.$root.$on('emit-initial-notifications', (notifications: SignalRModelRequest[]) => {
      let initialNotifications: SignalRModelRequest[] = [];
      initialNotifications = this.signalR.GetSignalRModelRequestFromLogs(notifications);
      const self = this;
      initialNotifications.forEach(function(item: SignalRModelRequest) {
        const pItem: any = JSON.parse(self.jsonEscape(String(item.JSONData)));
        item.Description = self.$t(String(item.SuccessMessage), {
          selectedCount: pItem.selectedCount,
          user: self.signalR.GetUserName(item, self.$t('UserName.You').toString()),
          selectedName: self.signalR.GetSeletedItemName(item).toString(),
        }).toString();
      });
      this.localNotifications = initialNotifications;
    });
  }

  private jsonEscape(str: string)  {
    return str.replace(/\n/g, '\\\\n').replace(/\r/g, '\\\\r').replace(/\t/g, '\\\\t');
  }
  // Add/Update value to Notifications array
  private AddUpdateNotificationStatus(notification: SignalRModelRequest) {
    const objIndex = this.localNotifications!.findIndex(
      (obj: SignalRModelRequest) => obj.GUID === notification.GUID,
    );
    notification = this.SetNotificationData(notification);
    const isLoggedInUserMe = this.IsLoggedInUserIsMe(notification);
    if (isLoggedInUserMe) {
      if (objIndex === -1) {
      // Insert only when the operation is started
      // if (notification.IsSuccess === false) {
        this.localNotifications!.unshift(notification);
        if (notification.IsSuccess) {
          this.ShowNotificationPopup(true);
          if (this.CheckIfAllNotificationsComplete()) {
            this.ShowNotificationLoader(false);
          }
          this.RefreshNotificationrelativeTime();
        } else {

          if (notification.Module === ModuleEnum.Program && notification.SubModule !== SubModuleEnum.SingleDetailEdit) {
            this.$root.$emit('clearCheckboxes', []);
          }

          if (notification.Module === ModuleEnum.Information && notification.SubModule !== SubModuleEnum.SingleDetailEdit) {
            this.$root.$emit('clearCustomCategoryCheckboxes', []);
          }
        }
      } else if (objIndex > -1) {
        Vue.set(this.localNotifications!, objIndex, notification); // Update array w reactivity
        this.ShowNotificationPopup(true);
        if (this.CheckIfAllNotificationsComplete()) {
          this.ShowNotificationLoader(false);
        }
        this.RefreshNotificationrelativeTime();
      }
    } else {
      if (objIndex === -1) {
        if (notification.IsSuccess) {
          this.localNotifications!.unshift(notification);
          this.ShowNotificationPopup(true);
          if (this.CheckIfAllNotificationsComplete()) {
            this.ShowNotificationLoader(false);
          }
          this.RefreshNotificationrelativeTime();
        }
      }
    }
  }
  private RefreshNotificationrelativeTime() {
    this.localNotifications!.forEach((element) => {
      element.RelativeResultDateTime = SignalRModelRequest.ToRelativeDate(element.ResultDateTime);
    });
  }
  private SetNotificationData(notification: SignalRModelRequest): SignalRModelRequest {
    if (notification.RelativeResultDateTime === '' || notification.RelativeResultDateTime == null) {

      notification.RelativeResultDateTime = this.$t('now').toString();
    }
    if(notification.SelectedIds !== null) {
      if (notification.IsSuccess) {
        notification.Description = this.$t(notification.SuccessMessage, {
          selectedCount: notification.SelectedIds.length,
          user: this.signalR.GetUserName(notification, this.$t('UserName.You').toString()),
          selectedName: this.signalR.GetSeletedItemName(notification).toString(),
        }).toString();
      } else {
        notification.Description = this.$t(notification.SubmitMessage, {
          selectedCount: notification.SelectedIds.length,
          user: this.signalR.GetUserName(notification, this.$t('UserName.You').toString()),
          selectedName: this.signalR.GetSeletedItemName(notification).toString(),
        }).toString();
      }
    } else {
      // In case of import when we don't have SelectedIds
      if(notification.IsSuccess === true) {
        notification.Description = this.$t(notification.SuccessMessage, {
          user: this.signalR.GetUserName(notification, this.$t('UserName.You').toString()),
        }).toString();
      } else {
        notification.Description = this.$t(notification.SubmitMessage, {
          user: this.signalR.GetUserName(notification, this.$t('UserName.You').toString()),
        }).toString();
      }
    }
    return notification;
  }
  // Clear All Notifications
  private ClearAllNotifications() {
    this.localNotifications = [];
    this.ShowNotificationLoader(false);
  }
  // Format Time to show result date time
  private formatTime(ResultDateTime: Date): string {
    const date = new Date(ResultDateTime);
    return date.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
    });
  }
  // Show/Hide notification popup
  private ShowNotificationPopup(val: boolean) {
    this.$root.$emit('show-notification-popup', val);
  }
  // Show/Hide notification loader gif
  private ShowNotificationLoader(val: boolean) {
    this.$root.$emit('show-notification-loading', val);
  }

  // Check if any bulk operation in progress
  private CheckIfAllNotificationsComplete(): boolean {
    let completed = true;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.localNotifications!.length; i++) {
      if (this.localNotifications![i].IsSuccess === false && this.localNotifications![i].NotificationStatus === NotificationStatusEnum.Sending) {
        completed = false;
        break;
      }
    }
    return completed;
  }

  private IsLoggedInUserIsMe(signalRModelRequest: SignalRModelRequest): boolean {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    return signalRModelRequest.UserId === reqInfo.Username;
  }
}
