



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import moment from 'moment';
@Component({
  components: {
    datetime: Datetime,
  },
})
export default class Datepicker extends Vue {
  @Prop() private Date?: Date;
  @Prop() private Disable?: boolean;
  @Prop() private FullDayTimeFormat?: boolean;
  @Prop() private DateOnly?: boolean;
  @Prop() private format?: string;
  @Prop() private IdValue?: string;
  @Prop() private mindatetime!: string;
  @Prop() private maxdatetime!: string;

  private iconurl: string = require('@/assets/Images/Calender.svg');
  private open = false;
  private localmindatetime = '';
  private localmaxdatetime = '';

  private mounted() {
    this.localmindatetime  = this.mindatetime;
    this.localmaxdatetime  = this.maxdatetime;
  }
  private SetDateTime(date: string) {
    this.$emit('selectedUTCDate', date);
    if (date !== '') {
      const selectedate: Date = new Date(date);
      selectedate.setSeconds(0);
      this.$emit('selectedDate', moment(selectedate).format());
    }
  }
  private openPicker() {
    const link = document.getElementById(this.IdValue!);
    if(link !== null && link !== undefined){
      link.click();
    }
  }
  get strStartDate() {
    if (this.Date != null) {
      return new Date(this.Date).toISOString();
    }
  }
  get isDisabled() {
    if (this.Disable != null) {
      return this.Disable;
    }
    return false;
  }
  get useFullDayTimeFormat() {
    if (this.FullDayTimeFormat != null) {
      return !this.FullDayTimeFormat;
    }
    return true;
  }
  get dateType() {
    let type = 'datetime';
    if (this.DateOnly != null) {
      switch (this.DateOnly) {
      case true:
        type = 'date';
        break;
      default:
        type = 'datetime';
        break;
      }
    }
    return type;
  }
  @Watch('mindatetime')
  private onmindatetimeChanged(val: string) {
    this.localmindatetime = val;
  }
}
