import { render, staticRenderFns } from "./LicenseDetails.vue?vue&type=template&id=77af2aac&scoped=true&"
import script from "./LicenseDetails.vue?vue&type=script&lang=ts&"
export * from "./LicenseDetails.vue?vue&type=script&lang=ts&"
import style0 from "./LicenseDetails.vue?vue&type=style&index=0&id=77af2aac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77af2aac",
  null
  
)

export default component.exports