









































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ApplicationViewModel } from '@/entities/Application/ApplicationViewModel';
import loading from '@/components/Common/loading.vue';
import LicenseDetails from '@/components/Application/LicenseDetails.vue';
import NotificationPopup from '@/components/Common/NotificationPopup.vue';
import LatestNews from '@/components/Application/LatestNews.vue';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { ProgramSignalRModel } from './signalRRequest/Program/ProgramSignalRModel';
import { BrowserSettingsInfo } from './entities/BrowserSettingsInfo';
import { AppColorSettingsInfo } from './entities/Application/AppColorSettingsInfo';
import OnboardingInstructions from '@/components/Application/OnboardingInstructions.vue';
import TrialPackageIndicator from '@/components/Application/TrialPackageIndicator.vue';
import * as hub from '@/hubs/hub';

@Component({
  components: {
    LatestNews,
    loading,
    LicenseDetails,
    NotificationPopup,
    OnboardingInstructions,
    TrialPackageIndicator,
  },
})
export default class App extends Vue {
  public showLoading = false;
  public isLeftMenuBarShow =  false;
  public mainContentClass = 'main-content';
  public AppColorAndBackgroundColor: any = {
    color: '',
    backgroundColor: '',
    visibility: 'hidden',
  };
  public AppColor: any = { color: '' };
  private appCss = require('@/assets/css/app.css');
  private mainCss = require('@/assets/css/main.css');
  private modalpopupCss = require('@/assets/css/modalpopup.css');
  private batchoperationsCSS = require('@/assets/css/batchoperations.css');
  private gridlistingCss = require('@/assets/css/gridlisting.css');
  private editPaneCss = require('@/assets/css/editPane.css');
  private landingCss = require('@/assets/css/landingPages.css');
  private listingsCSS = require('@/assets/css/listings.css');
  private toggleCss = require('@/assets/css/toggleswitch.css');
  private sliderCSS = require('@/assets/css/slider.css');
  private IconFile: string = require('@/assets/Images/VentlaLogo/Ventla-Logo-Grey.svg');
  private currentYear: number = new Date().getUTCFullYear();
  private mouseOverOnNotification = false;
  private notificationPopupVisible = false;
  private showLatestNewsPopup = false;
  private showLicenseDetailsPopup = false;
  private showOnboardingPopup = false;
  private ViewModel: ApplicationViewModel = ApplicationViewModel.empty();
  private toggledNav = false;
  private loadingImage: string = require('@/assets/Images/loading-dots-white.gif');
  private showNotificationLoading = false;
  private initialNotificationsCount = 20;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private meetappSupportURL = 'https://meetappsupport.typeform.com/to/w6PRZh';
  private meetappSignoutURL = '';
  private isLoggedInUserSuperAdmin = false;
  private isLoggedInUserApplicationAdmin = false;
  private isLoggedInUserEventAdmin = false;
  private isLoggedInUserAADAdmin = false;
  private isLoggedinUserOAuthAdmin = false;
  private activeComponent = 'eventsetting';
  private isaddVideoOverlay = false;
  private isFreezeScreenMsg = false;
  private showNotificationIcon = true;

  private CloseLatestNewsPopup() {
    this.showLatestNewsPopup = false;
  }
  private CloseOnboardingPopup() {
    this.showOnboardingPopup = false;
  }
  private CheckLoggedInUserAdmin() {
    if (this.ViewModel.LoggedInUserInformation.AdminTypeForUpperRightCorner === 'Super Admin') {
      this.isLoggedInUserSuperAdmin = true;
    } else if (this.ViewModel.LoggedInUserInformation.AdminTypeForUpperRightCorner === 'Application Admin') {
      this.isLoggedInUserApplicationAdmin = true;
    } else if (this.ViewModel.LoggedInUserInformation.AdminTypeForUpperRightCorner === 'Event Admin') {
      this.isLoggedInUserEventAdmin = true;
    }

    if (this.ViewModel.LoggedInUserInformation.IsActiveDirectoryAdminUser) {
      this.isLoggedInUserAADAdmin = true;
    } else if (this.ViewModel.LoggedInUserInformation.IsOAuthAdminUser) {
      this.isLoggedinUserOAuthAdmin = true;
    }
  }
  private closePopup(closePopup: boolean) {
    this.showLicenseDetailsPopup = closePopup;
  }

  private updated() {
    this.GetSelectedLeftSideMenu();
  }
  private setActiveComponent(comp: string) {
    this.activeComponent = comp;
  }
  private GetSelectedLeftSideMenu() {
    this.setActiveComponent(this.$route.name == null ? 'eventsetting' : this.$route.name);
  }
  private ShowBellIcon() {
    if (window.location.href.indexOf('eventsetting') > -1  || window.location.href.indexOf('watchlive') > -1) {
      this.showNotificationIcon  = false;
      return false;
    } else {
      this.showNotificationIcon = true;
      return true;
    }
  }
  private mounted() {
    Vue.use(hub);
    Vue.prototype.startSignalR(this);
    this.showLoading = true;
    this.GetApplicationViewModel();
    this.$root.$on('hide-parent-loader', (hideloader: boolean) => {
      if (hideloader === true) {
        this.showLoading = false;
      }
    });
    this.$root.$on('reload-GetApplicationViewModel', () => {
      this.GetApplicationViewModel();
    });

    this.$root.$on('show-notification-popup', (show: boolean) => {
      if (show === true) {
        this.ShowNotificationPopup();
      } else {
        this.notificationPopupVisible = false;
      }
    });

    this.$root.$on('show-notification-loading', (show: boolean) => {
      if (show === true) {
        this.ShowNotificationLoading();
      } else {
        this.HideNotificationLoading();
      }
    });
    this.$root.$on('freeze-screen', (val: boolean, userName: string) => {
      const storeHelper: StoreHelper = new StoreHelper(this.$store);
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      if (reqInfo.Username === userName) {
        this.isaddVideoOverlay = val;
        this.isFreezeScreenMsg = val;
      }
    });
    this.CheckLocaleAndTimeFormat();

    this.$root.$on('update-event-name', (val: string) => {
      this.ViewModel.ActiveEvent.Name = val;
    });
    this.$root.$on('set-active-component', (val: string)=> {
      this.activeComponent = val;
    });
  }
  private GetApplicationViewModel() {
    this.$axios
      .post<ApplicationViewModel>('/Application/GetApplicationViewModel')
      .then((response) => {
        this.ViewModel=new ApplicationViewModel(
          response.data.ApplicationInformation,
          response.data.Events,
          response.data.LoggedInUserInformation,
          this.$EnvironmentBaseURL,
          response.data.MuxEnvironmentKey,
        );
        this.AppColorAndBackgroundColor={
          color: response.data.ApplicationInformation.TopNavForegroundColor,
          backgroundColor: response.data.ApplicationInformation.TopNavBackgroundColor,
          visibility: 'visible',
        };
        this.AppColor={
          color: response.data.ApplicationInformation.TopNavForegroundColor,
        };
        this.SaveValueToStore();
        this.CheckLoggedInUserAdmin();
        this.GetSignoutUrl();
        this.showLoading=false;
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('error');
      });
  }

  private MouseOverNotification(mouseOver: boolean) {
    this.mouseOverOnNotification = mouseOver;
    this.ShowNotificationPopup();
  }
  // Show Popup on click of license details link
  private OpenLicenseDescription() {
    this.showLicenseDetailsPopup = true;
  }

  private created() {
    window.GetVueComponentReference(this);
  }
  private showOnboarding() {
    this.showOnboardingPopup = true;
  }
  private CheckLocaleAndTimeFormat() {
    let locale = window.navigator.language;
    // IE 11 => detect IE 11 browser
    const ua = window.navigator.userAgent;
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      locale = locale.split('-')[0];
    }
    const browserSettingsInfo: BrowserSettingsInfo = BrowserSettingsInfo.createEmpty();
    browserSettingsInfo.locale = locale;
    const date = new Date();
    const dateString = date.toLocaleTimeString();
    if (dateString.match(/am|pm/i) || date.toString().match(/am|pm/i) ) {
      // 12 hour clock
      browserSettingsInfo.isFullDayTimeFormat = false;
    } else {
      // 24 hour clock
      browserSettingsInfo.isFullDayTimeFormat = true;
    }
    this.storeHelper.SaveBrowserSettingsToStore(browserSettingsInfo);
  }
  private OpenNewsFlash() {
    this.showLatestNewsPopup = true;
  }
  private SaveValueToStore() {
    const reqInfo: AppRequestInfo = AppRequestInfo.createEmpty();
    reqInfo.ApplicationId = this.ViewModel.ApplicationInformation.Id;
    reqInfo.ApplicationInstanceId = this.ViewModel.ActiveEvent.Id;
    if(reqInfo.ApplicationInstanceId > 0) {
      this.isLeftMenuBarShow = true;
      this.mainContentClass = 'main-content';
    } else {
      this.mainContentClass = 'main-content1';
    }
    const currentUrl = window.location.href;
    if(currentUrl.includes('eventsetting')) {
      this.showNotificationIcon = false;
    } else {
      this.showNotificationIcon = true;
    }
    reqInfo.Authorization = this.$axios.defaults.headers.common.Authorization;
    reqInfo.EnvironmentBaseURL = Vue.prototype.$EnvironmentBaseURL;
    reqInfo.SignalRBaseURL = Vue.prototype.$SignalRBaseURL;
    reqInfo.UserId = this.ViewModel.LoggedInUserInformation.UserId;
    reqInfo.Username = this.ViewModel.LoggedInUserInformation.Username;
    reqInfo.AdminType = this.ViewModel.LoggedInUserInformation.AdminTypeForUpperRightCorner;
    reqInfo.FirstName = this.ViewModel.LoggedInUserInformation.Firstname;
    reqInfo.LastName = this.ViewModel.LoggedInUserInformation.Lastname;
    reqInfo.UserEmail = this.ViewModel.LoggedInUserInformation.Email;
    reqInfo.UserHash = this.ViewModel.LoggedInUserInformation.UserHash;
    reqInfo.MuxEnvironmentKey = this.ViewModel.MuxEnvironmentKey;
    reqInfo.AllowEnableTimeZoneFeature = this.ViewModel.ApplicationInformation.AllowEnableTimeZoneFeature;
    reqInfo.EnableVirtualMeetingIntegration = this.ViewModel.ApplicationInformation.EnableVirtualMeetingIntegration;
    reqInfo.EnableIntegrateteamszoomWatchLive = this.ViewModel.ApplicationInformation.EnableIntegrateteamszoomWatchLive;
    reqInfo.EnableVimeoVideos = this.ViewModel.ApplicationInformation.EnableVimeoVideos;
    reqInfo.EnableVideoOnDemandCustomCategory = this.ViewModel.ApplicationInformation.EnableVideoOnDemandCustomCategory;
    reqInfo.EnableRankingDialogue = this.ViewModel.ApplicationInformation.EnableRankingDialogue;
    reqInfo.EnableMeetAppLive = this.ViewModel.ApplicationInformation.EnableMeetAppLive;
    reqInfo.EnableScalingDialogue = this.ViewModel.ApplicationInformation.EnableScalingDialogue;
    reqInfo.EnableVideoOnDemand = this.ViewModel.ApplicationInformation.EnableVideoOnDemand;
    reqInfo.EnableMeetAppLiveArchiving = this.ViewModel.ApplicationInformation.EnableMeetAppLiveArchiving;
    reqInfo.IsCurrentPackageTrial = this.ViewModel.ApplicationInformation.IsCurrentPackageTrial;
    reqInfo.EnableParticipantList = this.ViewModel.ActiveEvent.EnableParticipantList;
    reqInfo.EnableLinkProgramItemsToGroup = this.ViewModel.ApplicationInformation.EnableLinkProgramItemsToGroup;
    reqInfo.EnableLinkParticipantToInformation = this.ViewModel.ApplicationInformation.EnableLinkParticipantToInformation;
    reqInfo.IsApplicationOnNewPackage = this.ViewModel.ApplicationInformation.IsApplicationOnNewPackage;
    reqInfo.IsDepartmentAdminFeatureEnable = this.ViewModel.ApplicationInformation.IsDepartmentAdminFeatureEnable;
    reqInfo.IsApplicationAdmin = this.ViewModel.LoggedInUserInformation.IsApplicationAdmin;
    reqInfo.IsLiveStreamingActive = this.ViewModel.ApplicationInformation.IsLiveStreamingActive;
    reqInfo.VerifyThresholdLimit = this.ViewModel.ApplicationInformation.VerifyThresholdLimit;
    reqInfo.IsThresholdLimitReached = this.ViewModel.ApplicationInformation.IsThresholdLimitReached;
    reqInfo.IsDepartmentAdmin = this.ViewModel.LoggedInUserInformation.IsDepartmentAdmin;
    reqInfo.DepartmentId = this.ViewModel.LoggedInUserInformation.DepartmentId;
    reqInfo.IsEventAdmin = this.ViewModel.LoggedInUserInformation.IsEventAdmin;
    reqInfo.IsSuperAdmin = this.ViewModel.LoggedInUserInformation.IsSuperAdmin;
    reqInfo.MaxAllowedAppInstances = this.ViewModel.ApplicationInformation.MaxAllowedAppInstances;
    reqInfo.TotalConsumedEvents = this.ViewModel.ApplicationInformation.TotalConsumedEvents;
    reqInfo.ThrehsholdEventsNumber = this.ViewModel.ApplicationInformation.ThrehsholdEventsNumber;
    reqInfo.CurrentEventsNumber = this.ViewModel.ApplicationInformation.CurrentEventsNumber;
    reqInfo.AdditionalEvents = reqInfo.ThrehsholdEventsNumber - reqInfo.CurrentEventsNumber;
    reqInfo.CreateAccountType = this.ViewModel.ApplicationInformation.CreateAccountType;
    reqInfo.CreateAccountTypeId = this.ViewModel.ApplicationInformation.CreateAccountTypeId;
    reqInfo.IsRegistrationPFActive = this.ViewModel.ApplicationInformation.IsRegistrationPFActive;
    reqInfo.EnableRTMPOnEventSettings = this.ViewModel.ApplicationInformation.EnableRTMPOnEventSettings;
    reqInfo.IsParticipantSelfCheckInActive = this.ViewModel.ApplicationInformation.IsParticipantSelfCheckInActive;
    reqInfo.LogoURI = this.ViewModel.ApplicationInformation.LogoUri;
    reqInfo.LogoUriDarkMode = this.ViewModel.ApplicationInformation.LogoUriDarkMode;
    reqInfo.EnableDialogueThemes = this.ViewModel.ApplicationInformation.EnableDialogueThemes;
    reqInfo.EnableCustomMenuNames = this.ViewModel.ApplicationInformation.EnableCustomMenuNames;
    reqInfo.EnableUserEditedParticipant = this.ViewModel.ApplicationInformation.EnableUserEditedParticipant;
    reqInfo.IsEventWebPagePFActive = this.ViewModel.ApplicationInformation.IsEventWebPagePFActive;
    reqInfo.EnableEventWebPage = this.ViewModel.ActiveEvent.EnableEventWebPage;
    reqInfo.IsZapierPFActive = this.ViewModel.ApplicationInformation.IsZapierPFActive;
    reqInfo.ZapierKey = this.ViewModel.ApplicationInformation.ZapierKey;
    reqInfo.IsZapierAvailableInApplicationZapierCallback = this.ViewModel.ApplicationInformation.IsZapierAvailableInApplicationZapierCallback;

    this.storeHelper.SaveValueToStore(reqInfo);
    this.storeHelper.SaveActiveEventStartDate(this.ViewModel.ActiveEvent.StartDate);
    this.storeHelper.SaveActiveEventIanaTimeZone(this.ViewModel.ActiveEvent.IanaTimeZone);
    this.storeHelper.SaveEnableProgramQuestionValue(this.ViewModel.ApplicationInformation.EnableProgramQuestion);
    this.storeHelper.SaveEnableProgram(this.ViewModel.ActiveEvent.EnableProgram);
    this.storeHelper.SaveEnableInformation(this.ViewModel.ActiveEvent.EnableInformation);
    const appDefaultColorsInfo: AppColorSettingsInfo = AppColorSettingsInfo.createEmpty();
    appDefaultColorsInfo.topNavForegroundColor = this.ViewModel.ApplicationInformation.TopNavForegroundColor;
    appDefaultColorsInfo.topNavBackgroundColor = this.ViewModel.ApplicationInformation.TopNavBackgroundColor;
    this.storeHelper.SaveAppColorSettingsToStore(appDefaultColorsInfo);
    this.storeHelper.SaveEventNameToStore(this.ViewModel.ActiveEvent.Name);

    this.storeHelper.SaveConsumptionBasedProductPricingToStore(this.ViewModel.ApplicationInformation.ConsumptionBasedProductPricing!);
    if(this.ViewModel.ActiveEvent.Id > 0) {
      this.FillNotificationsPopup();
    }
    this.$root.$emit('reload-eventsettingtab');
  }
  private GetLoggedInUserName() {
    const adminFirstName = this.ViewModel.LoggedInUserInformation.Firstname;
    const adminLastName = this.ViewModel.LoggedInUserInformation.Lastname;
    return adminFirstName + ' ' + adminLastName;
  }
  private OpenHelpLink() {
    window.open('https://support.ventla.io/kb/', '_blank');
  }
  private FillNotificationsPopup() {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    signalR.GetInitialNotifications(this.initialNotificationsCount);
  }

  private ToggleNotificationPopup(event: any) {
    if(this.notificationPopupVisible) {
      this.HideNotificationPopup();
    } else {
      this.ShowNotificationPopup();
    }
    event.stopPropagation();

  }
  private ClickedInsidePopup( event: any) {
    event.stopPropagation();
  }
  private ShowNotificationPopup() {
    this.notificationPopupVisible = true;
    this.$root.$emit(
      'reload-all-notifications',
    );
    setTimeout(() => {
      this.HideNotificationPopup();
    }, 3000);
  }

  private HideNotificationPopup() {
    if (!this.mouseOverOnNotification) {
      this.notificationPopupVisible = false;
    }
  }

  private ShowNotificationLoading() {
    this.showNotificationLoading = true;
  }
  private HideNotificationLoading() {
    this.showNotificationLoading = false;
  }
  private CheckAndHideNotificationPopup() {
    if (this.notificationPopupVisible === true) {
      this.notificationPopupVisible = false;
    }
  }

  private GetSignoutUrl() {
    if (this.ViewModel.ApplicationInformation.EnableCustomOAuthAuthorization) {

      this.meetappSignoutURL = this.$EnvironmentBaseURL + '/Admin/Auth/AuthSignOut?Id=' + this.ViewModel.ApplicationInformation.Id;
    } else {
      this.meetappSignoutURL = this.$EnvironmentBaseURL + '/Admin/Auth/SignOut';
    }
  }
  private ShowWatchLive(): boolean {
    if(this.ViewModel.ActiveEvent.Id > 0) {
      if(this.ViewModel.ApplicationInformation.IsLiveStreamingActive && this.ViewModel.ActiveEvent.EnableLiveStreaming) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  private ShowEventWebPage() {
    if(this.ViewModel.ActiveEvent.Id > 0) {
      if(this.ViewModel.ApplicationInformation.IsEventWebPagePFActive && this.ViewModel.ActiveEvent.EnableEventWebPage) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
