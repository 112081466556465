import { VodSignalRStatus } from '@/entities/VodDetail';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

export class SignalRModelRequest {

  public static ToRelativeDate(resultDate: Date): string {
    let result = 'now';
    const SECOND = 1;
    const MINUTE: number = 60 * SECOND;
    const HOUR: number = 60 * MINUTE;
    const DAY: number = 24 * HOUR;
    const MONTH: number = 30 * DAY;
    const YEAR: number = 365 * DAY;
    const currentDate = new Date().getTime();
    let createdDate = 0;
    createdDate = new Date(resultDate).getTime();
    const ts = (currentDate - createdDate);
    const delta = Math.floor(ts / 1000);
    if (delta < 1 * MINUTE) {
      const seconds = delta;
      if (seconds <= 0) {
        result = 'now';
      } else if (seconds === 1) {
        result = 'one second ago';
      } else {
        result = seconds + ' seconds ago';
      }
    } else if (delta < 2 * MINUTE) {
      result = 'a minute ago';
    } else if (delta < 45 * MINUTE) {
      result = Math.floor( delta / MINUTE ) + ' minutes ago';
    } else if (delta < 90 * MINUTE) {
      result = 'an hour ago';
    } else if (delta < 24 * HOUR) {
      result = Math.floor(delta / HOUR) + ' hours ago';
    } else if (delta < 48 * HOUR) {
      result = 'yesterday';
    } else if (delta < 30 * DAY) {
      result = Math.floor(delta / DAY) + ' days ago';
      result = 'few days ago';
    } else if (delta < 12 * MONTH) {
      const months = Math.floor(delta / MONTH);
      result = months <= 1 ? 'one month ago' : months + ' months ago';
    } else {
      const years = Math.floor(delta / YEAR);
      result = years <= 1 ? 'one year ago' : years + ' years ago';
    }
    return result;
  }
  constructor(
    public Id: number = 0,
    public Module: number = 0,
    public SubModule: number = 0,
    public ApplicationId: number = 0,
    public EventId: number = 0,
    public GUID: string = '',
    public UserId: string = '',
    public UserName: string = '',
    public AdminType: string = '',
    public RequestedUrl: string = '',
    public SelectedIds: number[] = [],
    public Heading: string = '',
    public Description: string = '',
    public SubmitMessage: string = '',
    public SuccessMessage: string = '',
    public IsSuccess: boolean = false,
    public Exception: any = null,
    public ErrorMessage: string = '',
    public ShouldRefreshBrowser: boolean = false,
    public ResultDateTime: Date = new Date(),
    public JSONData: string = '',
    public NotificationStatus: number = NotificationStatusEnum.Sending,
    public RelativeResultDateTime: string = '',
    public HubConnectionActionName: string = '',
    public VodDetail?: VodSignalRStatus|null,
  ) {
    // Do nothing
  }
}

export class BaseSignalRModelRequestJSON {
  constructor(
    public selectedCount: number = 0,
    public selectedName: string = '',
  ) {
    // Do nothing
  }
}
