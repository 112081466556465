




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoreHelper } from '@/store/StoreHelper';
import { LatestNewsRequestInfo } from '@/entities/Application/LatestNewsRequestInfo';
import loading from '@/components/Common/loading.vue';
@Component({
  components: {
    loading,
  },
})
export default class LatestNews extends Vue {
  @Prop() private showModalPopup?: boolean;
  @Prop() private borderColor?: string;
  private localBorderColor = '';
  private localShowModalPopup?: boolean = false;
  private showLoading = false;
  private newsContent = '';
  private newsTitle = '';
  private newsImgUrl = '';
  private Cancel() {
    this.$emit('close-popup', false);
  }
  private GetLatestNews() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const latestNewsInfo: LatestNewsRequestInfo = storeHelper.GetLatestNewsFromStore();
    if (latestNewsInfo) {
      this.newsTitle = latestNewsInfo.Title;
      this.newsImgUrl = latestNewsInfo.ImageUrl;
      this.newsContent = latestNewsInfo.Description;
      this.localShowModalPopup = this.showModalPopup;
    } else {
      this.showLoading = true;
      this.$axios
        .post('/Common/GetLatestNewsCookieInfo')
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const storeHelper: StoreHelper = new StoreHelper(this.$store);
          if (response.data.isPublished === true) {
            this.newsContent = response.data.description;
            this.newsTitle = response.data.title;
            this.newsImgUrl = response.data.imgUrl;
            const latestNews: LatestNewsRequestInfo = new LatestNewsRequestInfo(
              Boolean(response.data.isPublished),
              this.newsTitle,
              this.newsContent,
              this.newsImgUrl,
            );
            storeHelper.SaveLatestNewsToStore(latestNews);
            this.localShowModalPopup = this.showModalPopup;
          } else {
            this.localShowModalPopup = false;
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    }
  }
  private mounted() {
    this.localBorderColor = this.borderColor!;
    this.GetLatestNews();
  }
}
