/* eslint-disable max-len */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import Vuelidate from 'vuelidate';
import axios, { AxiosInstance } from 'axios';
import { store } from '@/store/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


declare global {
  interface Window { $auth: any; GetVueComponentReference: any; $url: any }
}
Vue.prototype.$EnvironmentBaseURL = window.$url;
Vue.prototype.$SignalRBaseURL =  process.env.VUE_APP_SIGNALRBASEURL;
Vue.prototype.$ApplicationInsightsKey = process.env.VUE_APP_APPLICATIONINSIGHTSKEY;
const axiosInstance = axios.create({
  baseURL: Vue.prototype.$EnvironmentBaseURL + 'AdminV2Api',
});
const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: Vue.prototype.$ApplicationInsightsKey,
  connectionString: 'InstrumentationKey=' + Vue.prototype.$ApplicationInsightsKey,
  enableAutoRouteTracking: true,
} });
appInsights.loadAppInsights();
appInsights.trackPageView();
// tslint:disable-next-line: no-string-literal
axiosInstance.defaults.headers.common.Authorization = window.$auth;
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosInstance.defaults.transformResponse = [function transformResponse(data: any) {
  if (typeof data === 'string') {
    try {
      const msAjaxRe = new RegExp(/^\/Date\((d|-|.*)\)[\/|\\]$/);
      const customParser = (key: string, value: string) => {
        /// Replaces MS .NET MVC FORMATTED DATES WITH JavaScript dates.
        const match = msAjaxRe.exec(value);
        if (match) {
          const b = match[1].split(/[-+,.]/);
          return new Date(b[0] ? +b[0] : 0 - +b[1]);
        }
        return value;
      };
      if (data.indexOf('HTTP_POTENTIALLY_DANGEROUS_REQUEST') > 0) {
        const error: SyntaxError = new SyntaxError('');
        HandleError(error);
      } else {
        data = JSON.parse(data, customParser);
      }
    } catch (error) {

      if (error instanceof SyntaxError && data.indexOf('HTTP_POTENTIALLY_DANGEROUS_REQUEST') > 0) {
        HandleError(error);
      }
    }
  }
  return data;
}];

function HandleError(error: SyntaxError) {
  const errormsg = 'Request Validation has detected a potentially dangerous client input value, and processing of the request has been aborted';
  error.message = errormsg;
  alert(error.message);
  Promise.reject(error);
  appInsights.trackException({exception: error});
}
// Add a request interceptor
axiosInstance.interceptors.request.use(function(config) {
  // Do something before request is sent
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appInsights.trackEvent({name: config.url!});
  return config;
}, function(error) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  appInsights.trackException({exception: new Error(error.response)});
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  appInsights.trackException({exception: new Error(error.response)});
  // Do something with response error
  return Promise.reject(error);
});
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$appInsight = appInsights;
declare module 'vue/types/vue' {
  interface Vue {
    $EnvironmentBaseURL: string;
    $axios: AxiosInstance;
    $auth: string;
    $appInsight: ApplicationInsights;
    $url: string;
  }
}
Vue.config.productionTip = false;
new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
Vue.use(Vuelidate);
