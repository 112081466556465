






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import carousel from 'vue-owl-carousel';
@Component({
  components: {
    carousel,
  },
})
export default class OnboardingInstructions extends Vue {
  @Prop() private showModalPopup?: boolean;
  @Prop() private loggedInUserName?: string;
  private localShowModalPopup?: boolean = false;
  private workingWithEventsImage: string = require('@/assets/Images/OnboardingImages/working-w-events.gif');
  private programImage: string = require('@/assets/Images/OnboardingImages/program.gif');
  private informationImage: string = require('@/assets/Images/OnboardingImages/information.gif');
  private engagingImage: string = require('@/assets/Images/OnboardingImages/engaging.gif');
  private participantsImage: string = require('@/assets/Images/OnboardingImages/participants.gif');
  private lastoneImage: string = require('@/assets/Images/OnboardingImages/lastone.gif');
  private showVStartLine = false;
  private showVEndLine = true;
  private mounted() {
    this.localShowModalPopup = this.showModalPopup!;
  }
  private Cancel() {
    this.$emit('close-popup', false);
  }
  private changed(event: any) {
    this.showVStartLine = event.item.index > 0 ? true : false;
    this.showVEndLine = event.item.index < 7 ? true : false;
  }
}
